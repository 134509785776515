import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import "./App.css";
import html2canvas from "html2canvas";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


function App() {
  const [floorplan, setFloorplan] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [objectRotation, setObjectRotation] = useState(0);
  const [importedFileName, setImportedFileName] = useState("floorplan");
  const sxitems = [
    { img: "speaker.png", label: "Speaker" },
    { img: "access-point.png", label: "Access Point" },
    { img: "oe360-100.png", label: "360 Camera" },
    { img: "oe90.png", label: "90 Camera" },
    { img: "volumecontrol.png", label: "Volume Control" },
    { img: "zebra.png", label: "Zebra Printer" },
    { img: "epson.png", label: "Epson Printer" },
    { img: "receipt.png", label: "Receipt Printer" },
    { img: "phone.png", label: "Teams Phone" },
    { img: "1data.png", label: "1 Data" },
    { img: "2data.png", label: "2 Data" },
    { img: "3data.png", label: "3 Data" },
    { img: "4data.png", label: "4 Data" },
    { img: "6data.png", label: "6 Data" },
  ];
  const items = [
    { img: "speaker.png", label: "Speaker" },
    { img: "access-point.png", label: "Access Point" },
    { img: "oe360-100.png", label: "360 Camera" },
    { img: "oe90.png", label: "90 Camera" },
    { img: "volumecontrol.png", label: "Volume Control" },
    { img: "zebra.png", label: "Zebra Printer" },
    { img: "epson.png", label: "Epson Printer" },
    { img: "receipt.png", label: "Receipt Printer" },
    { img: "phone.png", label: "Teams Phone" },
    { img: "1data.png", label: "1 Data" },
    { img: "2data.png", label: "2 Data" },
    { img: "3data.png", label: "3 Data" },
    { img: "4data.png", label: "4 Data" },
    { img: "6data.png", label: "6 Data" },
  ];
  const [pdfFile] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [placedObjects, setPlacedObjects] = useState(new Set());
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [isInverted, setIsInverted] = useState(false);

  useEffect(() => {
    if (isDarkTheme) {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      document.body.classList.add("light");
      document.body.classList.remove("dark");
    }
  }, [isDarkTheme]);

  const createLegend = () => {
    const legendContainer = document.createElement("div");
    legendContainer.style.position = "absolute";
    legendContainer.className = "draggable-object";

    const legendContent = document.createElement("div");
    legendContent.style.backgroundColor = "rgba(255, 255, 255, 0.8)";
    legendContent.style.border = "1px solid #000";
    legendContent.style.borderRadius = "4px";
    legendContent.style.padding = "10px";

    const legendList = document.createElement("ul");
    legendList.style.listStyleType = "none";
    legendList.style.padding = "0";
    legendList.style.margin = "0";

    const items = [
      { img: "1data.png", label: "1 Data" },
      { img: "2data.png", label: "2 Data" },
      { img: "3data.png", label: "3 Data" },
      { img: "4data.png", label: "4 Data" },
      { img: "6data.png", label: "6 Data" },
      { img: "oe90.png", label: "90 Camera" },
      { img: "oe360-100.png", label: "360 Camera" },
      { img: "speaker.png", label: "Speaker" },
      { img: "access-point.png", label: "Access Point" },
      { img: "volumecontrol.png", label: "Volume Control" },
      { img: "zebra.png", label: "Zebra Printer" },
      { img: "epson.png", label: "Epson Printer" },
      { img: "phone.png", label: "Teams Phone" },
      { img: "receipt.png", label: "Receipt Printer" },
    ];

    items.forEach((item) => {
      if (placedObjects.has(item.label)) {
        const listItem = document.createElement("li");
        listItem.style.display = "flex";
        listItem.style.alignItems = "center";
        listItem.style.marginBottom = "0px"; // Increase the margin-bottom to spread out the items

        const legendObject = document.createElement("div");
        legendObject.style.width = "25px";
        legendObject.style.height = "25px";
        listItem.appendChild(legendObject);

        if (item.img) {
          const legendImg = document.createElement("img");
          legendImg.src = item.img;
          legendImg.alt = item.label;
          legendImg.style.width = "25px";
          legendImg.style.height = "30px";
          legendObject.appendChild(legendImg);
        }

        const legendLabel = document.createElement("span");
        legendLabel.innerText = item.label;
        legendLabel.classList.add("legend-text");
        legendLabel.style.marginLeft = "10px";
        listItem.appendChild(legendLabel);

        legendList.appendChild(listItem);
      }
    });

    legendContent.appendChild(legendList);
    legendContainer.appendChild(legendContent);

    return legendContainer;
  };

  const undoLastObject = () => {
    const container = document.getElementById("floorplan-container");
    const draggableObjects = container.getElementsByClassName("draggable-object");

    if (draggableObjects.length > 0) {
      const lastObject = draggableObjects[draggableObjects.length - 1];
      if (lastObject.classList.contains("legend")) {
        const legendParent = document.querySelector(".legend-container");
        if (legendParent.contains(lastObject)) {
          legendParent.removeChild(lastObject);
        }
      } else {
        if (container.contains(lastObject)) {
          container.removeChild(lastObject);
        }
      }
    }
  };

  const handleRotationChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setObjectRotation(value);
  };

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileURL = URL.createObjectURL(file);
    setImportedFileName(file.name.split('.').slice(0, -1).join('.'));

    if (file.type === 'application/pdf') {
      try {
        const pdf = await pdfjs.getDocument(fileURL).promise;
        const page = await pdf.getPage(1); // Get the first page
        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement('canvas');
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const context = canvas.getContext('2d');
        await page.render({ canvasContext: context, viewport }).promise;
        const pngDataURL = canvas.toDataURL();
        setFloorplan(pngDataURL);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    } else {
      setFloorplan(fileURL);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    if (pageNumber > numPages) {
      setPageNumber(numPages);
    }
  };

  const onRenderSuccess = async (page) => {
    const viewport = page.getViewport({ scale: 1 });
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    await page.render({ canvasContext: context, viewport });
    const dataURL = canvas.toDataURL();
    setFloorplan(dataURL);
  };

  const handleObjectClick = (e) => {
    if (!selectedObject || !floorplan) return;

    const target = e.target;
    if (target.classList.contains("draggable-object")) return;

    const container = document.getElementById("floorplan-container");
    const containerRect = container.getBoundingClientRect();
    const x = e.clientX - containerRect.left;
    const y = e.clientY - containerRect.top;

    if (selectedObject === "legend") {
      const legend = createLegend();
      legend.style.left = `${x}px`;
      legend.style.top = `${y}px`;
      container.appendChild(legend);
    } else {
      const tempImg = new Image();
      tempImg.src = selectedObject;
      tempImg.onload = () => {
        const objectWidth = tempImg.naturalWidth;
        const objectHeight = tempImg.naturalHeight;

        const img = document.createElement("img");
        img.src = selectedObject;
        img.style.position = "absolute";
        img.style.left = `${x - objectWidth / 2}px`;
        img.style.top = `${y - objectHeight / 2}px`;
        img.style.transform = `rotate(${objectRotation}deg)`;
        img.className = "draggable-object";
        container.appendChild(img);
      };
      const itemName = items.find((item) => item.img === selectedObject).label;
      setPlacedObjects((prevState) => new Set([...prevState, itemName]));
    }
  };

  const saveFloorplan = () => {
    html2canvas(document.getElementById("floorplan-container")).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `${importedFileName}-modified.png`;
      link.click();
    });
  };

  const toggleTheme = () => {
    setIsDarkTheme((prev) => !prev);
  };

  const toggleInvert = () => {
    setIsInverted((prev) => !prev);
  };

  return (
    <div className={`App${isDarkTheme ? " dark" : ""}`}>
      <div className="toolbar">
        <div className="object-selector">
          <p>Select an object:</p>
          {items.map((item) => (
            <img
              key={item.img}
              src={item.img}
              alt={item.label}
              className={selectedObject === item.img ? "selected" : ""}
              onClick={() => setSelectedObject(item.img)}
            />
          ))}
          <button onClick={() => setSelectedObject("legend")}>Legend</button>

          {selectedObject && (
            <>
              <label htmlFor="rotation">Rotation: {objectRotation}°</label>
              <input
                type="range"
                id="rotation"
                name="rotation"
                min="0"
                max="315"
                step="45"
                value={objectRotation}
                onChange={handleRotationChange}
              />
            </>
          )}
        </div>
        <button onClick={undoLastObject}>Undo</button>
        <button onClick={saveFloorplan}>Save</button>
        <button onClick={toggleTheme}>
          {isDarkTheme ? "Light" : "Dark"} Theme
        </button>
        <button onClick={toggleInvert}>
          {isInverted ? "Normal Colors" : "Invert Colors"}
        </button>
      </div>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div id="floorplan-container" onClick={handleObjectClick} className="floorplan-container">
            {!floorplan && (
              <section>
                <div {...getRootProps()} className="dropzone">
                  <input {...getInputProps()} />
                  <p>Upload a floorplan</p>
                </div>
              </section>
            )}
            {pdfFile && (
              <Document
                file={pdfFile}
                onLoadSuccess={onDocumentLoadSuccess}
                renderMode="none"
              >
                <Page pageNumber={pageNumber} onRenderSuccess={onRenderSuccess} />
              </Document>
            )}
            {floorplan && <img src={floorplan} alt="Floorplan" className={isInverted ? "inverted" : ""} />}
          </div>
        )}
      </Dropzone>
    </div>
  );
}

export default App;
